import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: []
})
export class AuthCallbackComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router, private loc: PlatformLocation) { }

  ngOnInit(): void {
    this.authService.completeAuthentication().then((state) => {
      console.log(state);
      console.log(window.location.origin);
      console.log(this.loc);
      // return;
      const angularBase = window.location.origin + this.loc.getBaseHrefFromDOM();
      let navTarget = '';
      if (state && state.indexOf(angularBase) !== -1) {
        navTarget = state.replace(angularBase, '/');
      } else {
        navTarget = '/';
      }

      window.history.replaceState({},
        window.document.title,
        window.location.origin + window.location.pathname);
      console.log(navTarget);
      this.router.navigate([navTarget]);
    });
  }

}
