import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) { }


  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const settings = ConfigService.config;
    if (req.url.startsWith(settings.apiUrl)) {
      return this.authService
        .getAuthorizationHeaderValue()
        .pipe(
          mergeMap((authHeader: string) => {
            // console.log("headerAuth")
            if (authHeader && authHeader.length > 0) {
              const headers = req.headers.set('Authorization', authHeader);
              const authReq = req.clone({ headers });
              return next.handle(authReq)
                .pipe(tap(
                  (error: any) => {
                    const respError = error as HttpErrorResponse;
                    if (respError && (respError.status === 401 || respError.status === 403)) {
                      this.authService.tryLoginSilet();
                      this.router.navigate(['/unauthorized']);
                    }
                  })
                );
            }
            return next.handle(req);
          }));
    }
    else {
      return next.handle(req);
    }
  }
}
