import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Config } from '../model/config';


@Injectable()
export class ConfigService {

  constructor(private handler: HttpBackend, private http: HttpClient) {
    this.httpClient = new HttpClient(handler);
  }

  static config: Config;
  private jsonFile = 'assets/config/config.json';
  private httpClient: HttpClient;

  public load(): any {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(this.jsonFile)
        .subscribe((envResponse: any) => {
          ConfigService.config = envResponse as Config;
          resolve(true);
        }, error => {
          console.error(error);
          reject(`Could not load file '${this.jsonFile}': ${JSON.stringify(error)}`);
        });
    });
  }
}
