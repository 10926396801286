// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: window['env']['production'] === 'true' ? true : false,
  authenticationSettings: {
    authority: window["env"]["authority"] || 'https://login.microsoftonline.com/d38035db-fe09-4993-aeab-aa97022db11e/',
    client_id: window["env"]["client_id"] || 'c5d71fae-91cd-4ccc-adef-f274d5b77229',
    redirect_uri: window["env"]["redirect_uri"] || 'http://localhost:4200/auth-callback',
    silent_redirect_uri: window["env"]["post_logout_redirect_uri"] || 'http://localhost:4200/silent-redirect',
    post_logout_redirect_uri: window["env"]["post_logout_redirect_uri"] || 'http://localhost:4200/',
    response_type: window["env"]["response_type"] || 'id_token token',
    filterProtocolClaims: true,
    loadUserInfo: false,
    scope: window["env"]["scope"] || 'openid profile offline_access https://graph.microsoft.com/mail.read',
    automaticSilentRenew: true,
    resource: window["env"]["resource"] || 'https://bridgeit.dk/bmp',
  }
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
