import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCallbackComponent } from './shared/auth-callback/auth-callback.component';
import { AuthGuard } from './shared/services/auth.guard';
import { SilentRedirectComponent } from './shared/silent-redirect/silent-redirect.component';


const routes: Routes = [
  { path: '', loadChildren: () => import('../app/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
  { path: 'admin', loadChildren: () => import('../app/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
  { path: 'login', loadChildren: () => import('../app/login/login.module').then(m => m.LoginModule) },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'silent-redirect', component: SilentRedirectComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
