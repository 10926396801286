import { Component, OnInit } from '@angular/core';
import * as Oidc from 'oidc-client';

@Component({
  selector: 'app-silent-redirect',
  templateUrl: './silent-redirect.component.html',
  styleUrls: ['./silent-redirect.component.scss']
})
export class SilentRedirectComponent implements OnInit {

  constructor() {
    // Constructor
  }

  ngOnInit(): void {
    const config = {
      userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
    };
    new Oidc.UserManager(config)
      .signinSilentCallback()
      .then((tokenResponse) => {
        console.log('relogin silent');
      })
      .catch((err) => {
        console.log('relogin silent error ');
        console.log(err);
      });
  }

}
