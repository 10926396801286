import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { mergeMap } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) { }

  canActivate(): Observable<boolean> | boolean {
    return this.authenticationService.isLoggedIn()
      .pipe(mergeMap((isLogged: boolean) => {
        if (isLogged) {
          return of(isLogged);
        }
        this.router.navigate(['/login']);
        return of(false);
      }
      ));
  }
}
