import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserManager, User } from 'oidc-client';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public manager = new UserManager(environment.authenticationSettings);
  public user: User = null;

  constructor(private httpClient: HttpClient) {
    this.manager.getUser()
      .then(user => {
        console.log(user);
        this.user = user;
        console.log(this.user.profile);
        console.log(this.getAccessToken());
      });
  }

  isLoggedIn(): Observable<boolean> {
    const valuePromise = this.manager.getUser()
      .then(localuser => {
        if (localuser && !localuser.expired) {
          return true;
        } else {
          return false;
        }
      });

    return from(valuePromise);
  }

  getClaims(): any {
    return this.user.profile;
  }

  isUserInRole(userRole: string): Observable<boolean> {
    const rolePromise = this.manager.getUser()
      .then(localUser => {
        if (localUser.profile && localUser.profile.role) {
          // if only one role for user - a string is received, otherwise an array of roles is received
          if (typeof (localUser.profile.role) === 'string') {
            if (localUser.profile.role.toLowerCase() === userRole.toLowerCase()) {
              return true;
            }
            return false;
          }
          const roles = localUser.profile
            .role
            .filter(role => role.toLowerCase() === userRole.toLowerCase());
          if (roles.length > 0) {
            return true;
          }
        }
        return false;
      });

    return from(rolePromise);
  }

  getAuthorizationHeaderValue(): Observable<string> {

    const headerValuePromise = this.manager.getUser()
      .then(localuser => {
        if (localuser) {
          return `${localuser.token_type} ${localuser.access_token}`;
        }
        return '';
      });
    return from(headerValuePromise);
  }

  getAccessToken(): string {

    return this.user ? this.user.access_token : '';
  }

  startAuthentication(): Promise<void> {
    return this.manager.signinRedirect();
  }

  doSignout(): Promise<void> {
    return this.manager.signoutRedirect();
  }

  completeAuthentication(): Promise<string> {
    return this.manager.signinRedirectCallback().then(user => {
      this.user = user;
      return user.state;
    });
  }
  private dataSet(data, custom = false) {
    window.location.reload();
  }
  tryLoginSilet() {
    this.manager.signinSilent().then(tokenResponse => {
      // Callback code here
      console.log('test', tokenResponse);
    }).catch(error => {
      console.log('test2', error);
    });
  }


}
